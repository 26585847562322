import { Footer, SEO } from 'components';
import { motion } from 'framer-motion';
import { FormattedMessage, navigate, useIntl } from 'gatsby-plugin-intl';
import PageTransition from 'gatsby-v2-plugin-page-transitions';
import { MeasurementActions } from 'modules/measurements';
import { SportActions } from 'modules/sports';
import React from 'react';
import { useDispatch } from 'react-redux';
import { measuretype, titles, utils } from 'style';

const MeasureType: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  return (
    <PageTransition>
      <SEO
        title={intl.formatMessage({ id: 'measureTypePageSeo' })}
        url="https://find-your-fit.eu/en/measure-type"
        urlEN="https://find-your-fit.eu/en/measure-type"
        urlDE="https://find-your-fit.eu/de/measure-type"
        urlIT="https://find-your-fit.eu/it/measure-type"
        urlNL="https://find-your-fit.eu/nl/measure-type"
        urlFR="https://find-your-fit.eu/fr/measure-type"
      />
      <section css={[measuretype.root, utils.wrapper]}>
        <div css={measuretype.items}>
          <motion.div
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{ duration: 1.5, ease: [0.22, 1, 0.36, 1] }}
            css={measuretype.item}
          >
            <button onClick={handleChooseTapeMeasure}>
              <h2 css={[titles.secondary]}>
                <FormattedMessage id="imUsing" /> <br />
                <span css={utils.fontFamilySansAlt}>
                  <FormattedMessage id="tapeMeasure" />
                </span>
                <img src={require('assets/images/arrow.svg')} alt="" />
              </h2>
            </button>
          </motion.div>

          <motion.div
            css={measuretype.line}
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{
              delay: 0.25,
              duration: 1.5,
              ease: [0.22, 1, 0.36, 1],
            }}
          ></motion.div>

          <motion.div
            animate={{ y: [40, 0], opacity: [0, 1] }}
            transition={{
              delay: 0.5,
              duration: 1.5,
              ease: [0.22, 1, 0.36, 1],
            }}
            css={measuretype.item}
          >
            <button onClick={handleChooseMyBra}>
              <h2 css={[titles.secondary]}>
                <FormattedMessage id="imUsing" /> <br />
                <span css={utils.fontFamilySansAlt}>
                  <FormattedMessage id="myOwnBra" />
                </span>
                <img src={require('assets/images/arrow.svg')} alt="" />
              </h2>
            </button>
          </motion.div>
        </div>
      </section>
      <Footer />
    </PageTransition>
  );

  function handleChooseTapeMeasure() {
    dispatch(MeasurementActions.cleanAllMeasurements());
    dispatch(SportActions.cleanAllSports());
    navigate('/rib-measure');
  }

  function handleChooseMyBra() {
    dispatch(MeasurementActions.cleanAllMeasurements());
    dispatch(SportActions.cleanAllSports());
    navigate('/band-measure');
  }
};

export default MeasureType;
